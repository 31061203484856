import { GatsbyImage } from "gatsby-plugin-image"
import { Favorite } from "grommet-icons"
import React from "react"
import styled from "styled-components"
import getGatsbyImage from "utils/getGatsbyImage"
import Plan from "../../UI/Plan"
import scrollToPlansSection from "../actions/scrollToPlansSection"

const Wrapper = styled.div`
  position: relative;
  min-height: 300px;
  color: #fff;
  > :first-child {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  padding: 40px 15px;
`

const Content = styled.div`
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  padding-top: 30px;
  justify-content: center;
  text-align: center;
  > * {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  > :first-child {
    margin-right: 40px;
  }
  > :nth-child(2) {
  }
  @media (max-width: 825px) {
    flex-direction: column;
    align-items: center;
    > :first-child {
      margin-right: 0px;
      margin-bottom: 40px;
    }
  }
`

const Title = styled.h2`
  font-size: 2.2rem;
  margin: 0;
  margin-bottom: 30px;
  padding-top: 40px;
  text-align: center;
`

const Info = styled.div`
  line-height: 1.6;
`

const Points = styled.div`
  padding-top: 40px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  > * {
    margin-right: 14px;
    margin-bottom: 14px;
  }
  > :last-child {
    margin-right: 0;
  }
`

const Point = styled.div`
  display: flex;
  align-items: center;
  > :first-child {
    margin-right: 8px;
    * {
      fill: #98c355;
    }
  }
`

const MorePrices = styled.p`
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
  color: rgb(201, 129, 31);
  font-size: 1rem;
  margin-top: 20px;
`

interface IProps {
  title: string
  info: string
  plan: any
  hasMorePrices?: boolean
  backgroundImage: any
  points: {
    value: string
    bulletIcon: any
  }[]
}

export const formatAmount = (currency, amount, decimals: number = 2) => {
  let newAmount = Math.round(amount)
  const ZERO_DECIMALS = [
    "BIF",
    "CLP",
    "DJF",
    "GNF",
    "JPY",
    "KMF",
    "KRW",
    "MGA",
    "PYG",
    "RWF",
    "UGX",
    "VND",
    "VUV",
    "XAF",
    "XOF",
    "XPF",
  ]

  if (ZERO_DECIMALS.indexOf(currency.toUpperCase()) < 0) {
    newAmount /= 100
  }

  const formattedNumber = new Intl.NumberFormat("en-us", {
    style: "currency",
    currency,
    minimumFractionDigits: decimals,
  }).format(newAmount)

  return formattedNumber
}

const getMonthlyAmount = plan => {
  if (plan.interval === "month" && plan.interval_count === 3) {
    return `${formatAmount(plan.currency, plan.amount / 3)} / month`
  }
  if (plan.interval === "year" && plan.interval_count === 1) {
    return `${formatAmount(plan.currency, plan.amount / 12)} / month`
  }
  if (plan.interval === "month" && plan.interval_count === 1) {
    return `${formatAmount(plan.currency, plan.amount)} / month`
  }
  return ""
}

const getTitle = plan => {
  if (plan.interval === "month" && plan.interval_count === 3) {
    return "3 Months"
  }
  if (plan.interval === "year" && plan.interval_count === 1) {
    return "Yearly"
  }
  if (plan.interval === "month" && plan.interval_count === 1) {
    return "Monthly"
  }
  return ""
}

const getDescription = plan => `${plan.trial_period_days || 7} day free trial`

const getPriceInformation = plan => {
  if (plan.interval === "month" && plan.interval_count === 3) {
    return `Taken as a single payment of ${formatAmount(
      plan.currency,
      plan.amount
    )}`
  }
  if (plan.interval === "year" && plan.interval_count === 1) {
    return `Taken as a single payment of ${formatAmount(
      plan.currency,
      plan.amount
    )}`
  }
  return `Taken as a single payment of ${formatAmount(
    plan.currency,
    plan.amount
  )}`
}

export const formatStrapiPlanToPlanProps = props => {
  const { planId, highlighted, showSam, badgeText, plan, price } = props
  let baseProps: any = {
    planId,
    showSam,
    highlighted,
    badgeText,
    button: highlighted
      ? {
          theme: "confirm",
          value: "Start Free Trial",
        }
      : { backgroundColor: "#f8f8f8", value: "Start Free Trial" },
  }
  if (price) {
    const equivPlan = {
      currency: price.currency,
      interval: price.recurring.interval,
      interval_count: price.recurring.interval_count,
      amount: price.tiers
        ? price.tiers
            .sort((a, b) => a.up_to - b.up_to)
            .filter(b => b.up_to >= 1)[0].flat_amount
        : 0,
      trial_period_days: price.recurring.trial_period_days || 7,
    }
    baseProps = {
      ...baseProps,
      extraResources:
        price.product?.metadata?.includesResources === "true" &&
        price.recurring.interval === "year",
      // extraResources: price.recurring.interval === 'year',
      priceLabel: getMonthlyAmount(equivPlan),
      title: getTitle(equivPlan),
      description: getDescription(equivPlan),
      priceInformation: getPriceInformation(equivPlan),
    }
  } else if (plan) {
    baseProps = {
      ...baseProps,
      extraResources: false,
      priceLabel: getMonthlyAmount(plan),
      title: getTitle(plan),
      description: getDescription(plan),
      priceInformation: getPriceInformation(plan),
    }
  }
  return baseProps
}

const StrapiPlanHero = (props: IProps) => {
  const plan = {
    ...formatStrapiPlanToPlanProps(props.plan),
    linkTo: `checkout/${props.plan.planId}`,
  }

  // @ts-ignore
  return (
    <Wrapper className="PlansSection">
      {props.backgroundImage ? (
        <GatsbyImage
          image={getGatsbyImage(props.backgroundImage.localFile)}
          alt={props.title || ""}
        />
      ) : (
        <div />
      )}
      <Content>
        <div>
          <Title>{props.title}</Title>
          <Info>{props.info}</Info>
          {props.hasMorePrices && (
            <MorePrices onClick={() => scrollToPlansSection(".MorePlans")}>
              More prices
            </MorePrices>
          )}
        </div>
        <Plan {...plan} />
      </Content>
      {props.points && (
        <Points>
          {props.points.map((p, i) => (
            <Point key={i}>
              <Favorite color="#98C355" />
              <div>{p.value}</div>
            </Point>
          ))}
        </Points>
      )}
    </Wrapper>
  )
}

StrapiPlanHero.defaultProps = {
  hasMorePrices: false,
}

export default StrapiPlanHero
